@use './variables';
@use './mixin';


#testimonials-card {
    font-family: variables.$secondaryFontFamily;

    .testimonials-card-item {
        @include mixin.positionDisplay(flex, center, center);
        flex-direction: column;
        height: 28em;
        cursor: grab;
        margin: 5px 10px 15px;
        box-shadow: 1px 1px 15px -1px rgba(0, 0, 0, 0.63);
        -webkit-box-shadow: 1px 1px 15px -1px rgba(0, 0, 0, 0.247);
        -moz-box-shadow: 1px 1px 15px -1px rgba(0, 0, 0, 0.63);
    }

    font-family: variables.$primaryFontFamily;

    p {
        padding: 20px;
        font-size: 16px;
        font-style: italic;
        text-align: center;
        font-weight: 100;

        &::before {
            content: url("../../pages/HomePage/components/assets/img/quote-sign-left.png");
            margin-right: 20px;
        }

        &::after {
            content: url("../../pages/HomePage/components/assets/img/quote-sign-right.png");
            margin-right: 20px;
        }


    }

    .circle-image {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 20px;
        font-weight: bold;
        margin: 10px;
    }

    h3 {
        font-size: 17px;
        margin-bottom: 20px;
        opacity: 0.7;

    }

    .slick-next,
    .slick-prev {
        display: none !important;
    }

}